import React, { Component } from "react";
import Text from "../styles/Text";
import Input from "../styles/Input";
import Box from "../styles/Box";
import LightButton from "../styles/LightButton";
import styled from "styled-components";
import { graphql, StaticQuery } from "gatsby";
import Container from "../styles/Container";
import { padding } from "../../theme";
import Subhead from "../styles/Subhead";
import Heading from "../styles/Heading";
import addToMailchimp from "gatsby-plugin-mailchimp";
import emblem from "../../images/emblem.png";

const EmailInput = styled(Input)`
  background-color: white;
  color: gray;
  margin-right: 20px;
  border-radius: 0;
  height: 48px;
  padding-left: 20px;
`;

const Sub = styled(Subhead)`
  color: white !Important;
`;

const SubmitButton = styled(LightButton)`
  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed !Important;
  }
`;

const FormBanner = styled.div`
  min-height: 400px;
  /*
  max-width: 1400px; */
  text-decoration: none;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
  margin-bottom: 60px;
  padding-left: 5%;
  padding-right: 5%;
  background-color: rgb(123, 140, 149);
  color: white;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
`;

const BannerOverlay = styled.img`
  position: absolute;
  width: 40%;
  opacity: 10%;
  max-width: 400px;
`;

class MainComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: ""
    };
  }

  handleEmailChange = e => {
    this.setState({ email: e.target.value });
  };

  submitForm = e => {
    console.log(this.state.email);
    e.preventDefault();

    // if Input has value, send it to mailchimp. If not, alert the user it's blank

    // add subscriber to mailchimp list
    addToMailchimp(this.state.email)
      .then(data => {
        // if successful, display success message
        if (data.result == "success") {
          alert("Thanks for subscribing! We'll be in touch shortly!");

          // if failed, display error message
        } else if (data.result == "error") {
          alert(
            "Sorry, this email is already subscribed or our system is having a problem. Try a different email, or try again later."
          );
        }
        console.log(data);

        // clear email from form
        this.setState({
          email: ""
        });
      })
      .catch(error => {
        // Errors in here are client side
        // Mailchimp always returns a 200
      });
  };

  render() {
    return (
      <div>
        <FormBanner>
          <BannerOverlay src={emblem} />
          <Container style={{ zIndex: "10" }}>
            <Box py="50px">
              <Heading my={3} fontSize={6} mx="auto">
                {this.props.data.contentfulHomePage.element6FormTitle.toUpperCase()}
              </Heading>
              <Sub mt={3} mb={6} fontSize={4} color="#fff" mx="auto">
                {this.props.data.contentfulHomePage.element6FormSubhead}
              </Sub>

              <EmailInput
                placeholder="Your email"
                value={this.state.email}
                type="text"
                color="black"
                onChange={this.handleEmailChange}
                width="250px"
              />
              <Box my={6}>
                <SubmitButton
                  disabled={!this.state.email}
                  onClick={this.submitForm}
                >
                  Submit
                </SubmitButton>
              </Box>
            </Box>
          </Container>
        </FormBanner>
      </div>
    );
  }
}

export default () => (
  <StaticQuery
    query={graphql`
      query StayInTouchPlease {
        contentfulHomePage(live: { eq: true }) {
          id
          element6FormTitle
          element6FormSubhead
        }
      }
    `}
    render={data => <MainComponent data={data} />}
  />
);
