import React, { Component } from "react";
import { Flex, Heading } from "rebass";
import Subhead from "../styles/Subhead";
import Container from "../styles/Container";
import LightButton from "../styles/LightButton";

import styled from "styled-components";
import Img from "../styles/Img";
import Box from "../styles/Box";
import { StaticQuery, graphql } from "gatsby";

const BlockImage = styled.img`
  width: 500px;
  margin-left: auto;
  margin-right: auto;
`;

const ShopButton = styled(LightButton)`
  font-size: 22px !Important;
  font-weight: 700;
  line-height: 1.14286;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
  &:hover {
    transform: translate(1px, 1px);
    cursor: pointer;
  }
`;

export default () => (
  <StaticQuery
    query={graphql`
      query FullWidth {
        contentfulHomePage(live: { eq: true }) {
          id
          element8Title
          element8Image {
            fluid(maxWidth: 1200) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    `}
    render={data => (
      <div>
        <Container>
          <Box width={["100%", "50%"]} my="20px" alignItems="center" mx="auto">
            <Subhead pt={3} pb="30px" fontSize="42px">
              {data.contentfulHomePage.element8Title}
            </Subhead>
            <a href="https://modarri-toy-cars.myshopify.com/">
              <ShopButton>Shop Now</ShopButton>
            </a>
          </Box>
        </Container>
        <Img fluid={data.contentfulHomePage.element8Image.fluid} />
      </div>
    )}
  />
);
