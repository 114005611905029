import React from "react";
import styled from "styled-components";
import { Flex } from "rebass";
import Container from "../styles/Container";
import Link from "gatsby-link";
import LightButton from "../styles/LightButton";
import Img from "../styles/Img";
import Box from "../styles/Box";
import { StaticQuery, graphql } from "gatsby";
import Subhead from "../styles/Subhead";

import road from "../../images/road.svg";

import Hide from "hidden-styled";

const BannerHome = styled.div`
  text-decoration: none;
  max-width: 1500px;
  width: 100%;
  max-height: 1230px;
  height: 80vw;
  margin-bottom: 0;
`;

const Heading = styled.h1`
  font-family: "Source Sans Pro" !important;
  font-size: 36px;
  color: rgb(55, 65, 69);
  font-style: italic;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(255, 255, 255, 0.28);
  border-radius: 5px;
  margin-top: 50px;
  margin-bottom: 20px;
`;

const BlockImage = styled(Img)`
  width: 100%;
  max-width: 500px;
  min-width: 300px;
  margin-left: auto;
  margin-right: auto;
`;

const RoadImage = styled.img`
  width: 700px;
  position: absolute;
  right: 1px;
  top: 83px;
`;

const HomeSubhead = styled(Heading)`
  padding-bottom: 3px;
  color: #dee1e5;
`;

const ShopButton = styled(LightButton)`
  font-size: 22px !important;
  font-weight: 700;
  line-height: 1.14286;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
  &:hover {
    transform: translate(1px, 1px);
    cursor: pointer;
  }
`;

export default () => (
  <StaticQuery
    query={graphql`
      query HomeBanner {
        contentfulHomePage(live: { eq: true }) {
          id
          heroHeadline
          heroSubhead1
          heroSubhead2
          heroImage {
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
        }
      }
    `}
    render={(data) => (
      <div>
        <BannerHome>
          <Container my={1}>
            <Flex flexWrap="wrap" mx="auto">
              <Box
                width={["100%", "70%"]}
                mb="10px"
                alignItems="center"
                mx="auto"
              >
                <Subhead fontSize={["26px", "26px", "33px"]} mt="20px" mb="5px">
                  {data.contentfulHomePage.heroHeadline.toUpperCase()}
                </Subhead>
                <Subhead fontSize="18px" color="#808080" py="3px">
                  {data.contentfulHomePage.heroSubhead1}
                </Subhead>
                <Subhead fontSize="18px" color="#808080" py="3px">
                  {data.contentfulHomePage.heroSubhead2}
                </Subhead>
              </Box>
            </Flex>
          </Container>
          {/* <Box
            sx={{
              width: "100%",
              height: 0,
              paddingBottom: 900 / 16 + "%",
              marginLeft: "auto",
              marginRight: "auto",
              position: "relative",
              overflow: "hidden",
              "& > iframe": {
                position: "absolute",
                width: "100%",
                height: "100%",
                top: 150,
                bottom: 0,
                border: 0
              }
            }}
          >
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/UerDzUc5mfQ"
              frameBorder="0"
              allowFullScreen
            />
          </Box> */}

          <div
            dangerouslySetInnerHTML={{
              __html: `
<video playsinline
loop
autoplay
muted
  style="
  width: 100%;

  max-width: 2500px;
  position: absolute;
  left: 0;
  top: 270px;
  z-index:1;
"

  src=${require("../../images/homepage5.mp4")}
/>
`,
            }}
          />
        </BannerHome>
      </div>
    )}
  />
);
