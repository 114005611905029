import React, { Component } from "react";
import { Flex } from "rebass";
import Text from "../styles/Text";
import styled from "styled-components";
import Img from "../styles/Img";
import Box from "../styles/Box";
import { graphql, StaticQuery } from "gatsby";
import { padding } from "../../theme";
import Subhead from "../styles/Subhead";
import ig from "../../images/ig.png";
import fb from "../../images/fb.png";
import yt from "../../images/yt.png";
import Heading from "../styles/Heading";

const BlockImage = styled.img`
  width: 500px;
  margin-left: auto;
  margin-right: auto;
`;

const SocialIcon = styled.img`
  width: 100%;
  max-width: 60px;
`;

export default () => (
  <StaticQuery
    query={graphql`
      query Social {
        contentfulHomePage(live: { eq: true }) {
          element7Block1Title
          element7Block1Subhead
          element7Block1Subhead2
          element7Block1Subhead3
          element7Block1Image {
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid
            }
          }
          element7Block2Title
          element7Block2Subhead
          element7Block2Image {
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    `}
    render={data => (
      <div>
        <Flex py={padding} flexWrap="wrap">
          <Box width={[1, 1, 1 / 2]} px={3} color="rgb(55,65,69)">
            <Subhead pb={3}>
              {data.contentfulHomePage.element7Block1Title.toUpperCase()}
            </Subhead>
            {/* Inner Box 1 IG */}
            <Flex py={4} flexWrap="wrap">
              <Box width={1 / 6}>
                <a href="https://www.instagram.com/modarri" target="_blank">
                  <SocialIcon src={ig} />
                </a>
              </Box>
              <Box width={5 / 6}>
                <Text px={2} color="rgb(55,65,69)" fontSize="20px">
                  {data.contentfulHomePage.element7Block1Subhead}
                </Text>
              </Box>
            </Flex>

            {/* Inner Box 2 FB*/}
            <Flex flexWrap="wrap">
              <Box width={1 / 6}>
                <a href="https://www.facebook.com/modarri/" target="_blank">
                  {/* <Img
                    fluid={data.contentfulHomePage.element7Block1Image.fluid}
                  /> */}
                  <SocialIcon src={fb} />
                </a>
              </Box>
              <Box width={5 / 6}>
                <Text px={2} color="rgb(55,65,69)" fontSize="20px">
                  {data.contentfulHomePage.element7Block1Subhead2}
                </Text>
              </Box>
            </Flex>

            {/* Inner Box 3 YT*/}
            <Flex py={4} flexWrap="wrap">
              <Box width={1 / 6}>
                <a
                  href="https://www.youtube.com/channel/UC9o78LSi1BSOBocWXb-QKow"
                  target="_blank"
                >
                  <SocialIcon src={yt} />
                </a>
              </Box>
              <Box width={5 / 6}>
                <Text px={2} color="rgb(55,65,69)" fontSize="20px">
                  {data.contentfulHomePage.element7Block1Subhead3}
                </Text>
              </Box>
            </Flex>
          </Box>

          {/* Box 2 */}
          <Box width={[1, 1, 1 / 2]} px={3} color="black">
            {/* <a href="https://www.facebook.com/modarri/" target="_blank"> */}
            <Img fluid={data.contentfulHomePage.element7Block1Image.fluid} />

            {/* </a> */}
          </Box>
        </Flex>
      </div>
    )}
  />
);
