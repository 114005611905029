import React from "react";
import Link from "gatsby-link";

import Container from "../components/styles/Container";
import HomeBanner from "../components/home/HomeBanner";
import Page from "../components/Page";
import DesignCar from "../components/home/DesignCar";
import DesignBuildDrive from "../components/home/DesignBuildDrive";
import CollectAll from "../components/home/CollectAll";
import Recognition from "../components/home/Recognition";
import SocialBlock from "../components/home/Social";
import StayInTouch from "../components/home/StayInTouch";
import FullWidthImage from "../components/home/FullWidthImage";
import "../components/styles/styles.scss";
import FullWidthSecondary from "../components/home/FullWidthSecondary";
import UltimateDriving from "../components/home/UltimateDriving";
import BuiltToLast from "../components/home/BuiltToLast";
import MeettheParts from "../components/home/MeettheParts";

class IndexPage extends React.Component {
  render() {
    const { data } = this.props;

    return (
      <div>
        <Page>
          <HomeBanner />
          <FullWidthSecondary />

          <Container>
            <DesignBuildDrive />
          </Container>

          <Container>
            <UltimateDriving />
          </Container>

          <Container>
            <BuiltToLast />
          </Container>
          <Container>
            <MeettheParts />
          </Container>

          {/* <StayInTouch /> */}
          <FullWidthImage />

          {/* <Container>
            <SocialBlock />
          </Container> */}
        </Page>
      </div>
    );
  }
}

export default IndexPage;
