import React, { Component } from "react";
import { Flex } from "rebass";
import Text from "../styles/Text";
import Container from "../styles/Container";
import styled from "styled-components";
import Img from "../styles/Img";
import Box from "../styles/Box";
import { graphql, StaticQuery } from "gatsby";
import { padding } from "../../theme";
import Subhead from "../styles/Subhead";
import SecondaryButton from "../styles/SecondaryButton";
import LightButton from "../styles/LightButton";

const ImageLink = styled(Img)`
  &:hover {
    cursor: pointer;
  }
`;

const Link = styled.a`
  &:hover {
    cursor: pointer !Important;
  }
`;

const SSecondaryButton = styled(LightButton)`
  &:hover {
    cursor: pointer !Important;
  }
`;

export default () => (
  <StaticQuery
    query={graphql`
      query DesignCar {
        contentfulHomePage(live: { eq: true }) {
          id
          element2Title
          element2Subhead
          element2Image {
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <div>
        {/* <DesignBanner bg="base"> */}
        <Container>
          <Flex pb={padding} flexWrap="wrap">
            <Box
              width={[1, 1, 1 / 2]}
              px={4}
              pt={["10px", "10px", 0, "10px"]}
              color="black"
            >
              <Subhead fontSize="40px">
                {data.contentfulHomePage.element2Title.toUpperCase()}
              </Subhead>
              <Text pt={3} pb="30px" fontSize="20px">
                {data.contentfulHomePage.element2Subhead}
              </Text>
              <Link
                href="https://modarri-toy-cars.myshopify.com/products/configurator"
                target="_blank"
              >
                <SSecondaryButton>Design Now</SSecondaryButton>
              </Link>
            </Box>
            <Box width={[1, 1, 1 / 2]} px={4} color="white">
              <Link
                href="https://modarri-toy-cars.myshopify.com/products/configurator"
                target="_blank"
              >
                <ImageLink
                  py={3}
                  fluid={data.contentfulHomePage.element2Image.fluid}
                />
              </Link>
            </Box>
          </Flex>
        </Container>
        {/* </DesignBanner> */}
      </div>
    )}
  />
);
