import React from 'react';
import { Flex } from 'rebass';
import Text from '../styles/Text';
import styled from 'styled-components';
import Img from '../styles/Img';
import Box from '../styles/Box';
import { StaticQuery, graphql } from 'gatsby';
import { padding } from '../../theme';
import Subhead from '../styles/Subhead';

const BlockImage = styled.img`
  width: 500px;
  margin-left: auto;
  margin-right: auto;
`;

export default () => (
  <StaticQuery
    query={graphql`
      query MeettheParts {
        contentfulLearnMorePage(live: { eq: true }) {
          id
          element5Title
          element5Subhead
          element5Image {
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    `}
    render={data => (
      <div>
        <Flex pt={padding} flexWrap="wrap">
          <Box width={[1, 1, 1 / 2]} px={5} color="white">
            <Img
              py={3}
              fluid={data.contentfulLearnMorePage.element5Image.fluid}
            />
          </Box>
          <Box
            width={[1, 1, 1 / 2]}
            px={5}
            pt={['20px', '20px', 0, '80px']}
            color="black"
          >
            <Subhead px={2} fontSize="40px">
              {data.contentfulLearnMorePage.element5Title.toUpperCase()}
            </Subhead>
            <Text px={2} py={5} fontSize="20px">
              {data.contentfulLearnMorePage.element5Subhead}
            </Text>
          </Box>
        </Flex>
      </div>
    )}
  />
);
