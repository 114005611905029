import React, { Component } from "react";
import { Flex, Heading } from "rebass";
import Subhead from "../styles/Subhead";
import styled from "styled-components";
import Img from "../styles/Img";
import Box from "../styles/Box";
import { StaticQuery, graphql } from "gatsby";

const BlockImage = styled.img`
  width: 500px;
  margin-left: auto;
  margin-right: auto;
`;

export default () => (
  <StaticQuery
    query={graphql`
      query FullWidthSecondary {
        contentfulHomePage(live: { eq: true }) {
          id
          element2Subhead
          element2Image {
            fluid(maxWidth: 700) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    `}
    render={data => (
      <div>
        <Box width={["90%", "50%"]} mt={["115px", "0px"]} mx="auto">
          <Img
            width="50%"
            fluid={data.contentfulHomePage.element2Image.fluid}
          />
          <Subhead pt={3} pb="30px" fontSize="23px">
            {/* {data.contentfulHomePage.element2Subhead} */}Designed for hours
            of entertaining play!
          </Subhead>
        </Box>
      </div>
    )}
  />
);
