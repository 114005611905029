import React, { Component } from "react";
import { Flex } from "rebass";
import Text from "../styles/Text";
import styled from "styled-components";
import Img from "../styles/Img";
import Box from "../styles/Box";
import { StaticQuery, graphql } from "gatsby";
import Heading from "../styles/Heading";
import Subhead from "../styles/Subhead";
import { padding } from "../../theme";

const BlockImage = styled.img`
  width: 500px;
  margin-left: auto;
  margin-right: auto;
`;

export default () => (
  <StaticQuery
    query={graphql`
      query UltimateDriving {
        contentfulLearnMorePage(live: { eq: true }) {
          id
          element3Title
          element3Block1Title
          element3Block1Subhead
          element3Block1Image {
            fluid(maxWidth: 500) {
              ...GatsbyContentfulFluid
            }
          }
          element3Block2Title
          element3Block2Subhead
          element3Block2Image {
            fluid(maxWidth: 500) {
              ...GatsbyContentfulFluid
            }
          }
          element3Block3Title
          element3Block3Subhead
          element3Block3Image {
            fluid(maxWidth: 500) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    `}
    render={data => (
      <div>
        <Flex py={padding} flexWrap="wrap">
          <Box width={1} p={3}>
            <Heading fontSize={6} color="black">
              {data.contentfulLearnMorePage.element3Title.toUpperCase()}
            </Heading>
          </Box>
          <Box width={[1, 1, 1 / 3]} px={3} pt="70px" color="black">
            <Img
              py={5}
              fluid={data.contentfulLearnMorePage.element3Block1Image.fluid}
            />
            <Subhead pt={5} fontSize="40px">
              {data.contentfulLearnMorePage.element3Block1Title.toUpperCase()}
            </Subhead>
            <Text py={5} fontSize="20px">
              {data.contentfulLearnMorePage.element3Block1Subhead}
            </Text>
          </Box>
          {/* Box 2 */}
          <Box width={[1, 1, 1 / 3]} px={3} pt="70px" color="black">
            <Img
              py={5}
              fluid={data.contentfulLearnMorePage.element3Block2Image.fluid}
            />
            <Subhead pt={5} fontSize="40px">
              {data.contentfulLearnMorePage.element3Block2Title.toUpperCase()}
            </Subhead>
            <Text py={5} fontSize="20px">
              {data.contentfulLearnMorePage.element3Block2Subhead}
            </Text>
          </Box>
          {/* Box 3 */}
          <Box width={[1, 1, 1 / 3]} px={3} pt="70px" color="black">
            <Img
              py={5}
              fluid={data.contentfulLearnMorePage.element3Block3Image.fluid}
            />
            <Subhead pt={5} fontSize="40px">
              {data.contentfulLearnMorePage.element3Block3Title.toUpperCase()}
            </Subhead>
            <Text py={5} fontSize="20px">
              {data.contentfulLearnMorePage.element3Block3Subhead}
            </Text>
          </Box>
        </Flex>
      </div>
    )}
  />
);
